import React from "react";
import Layout from "../components/element/layout/Layout";
import routes from "../components/navigation/routes";
import { Redirect } from "@reach/router";
import PageBanner from "../components/element/page-element/PageBanner";
import LayoutMain from "../components/element/layout/LayoutMain";
import DeployWebsite from "../components/user/deploy/DeployWebsite";
import { oo } from "../components/element/theme";


const Dev = () => {
  let render;

  if (process.env.NODE_ENV === "development") {
    render = (
      <Layout>
        <PageBanner heading="Dev" />
        <LayoutMain>

          <div style={{ maxWidth: oo.contentWidth + "rem" }}>
            <DeployWebsite />
          </div>

        </LayoutMain>
      </Layout>
    );


  } else {
    render = <Layout showNewsletter><Redirect noThrow to={routes.home.to} /></Layout>;
  }
  return <>{render}</>;
};
export default Dev;
